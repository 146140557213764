<template>
        <section class="products-holder" :color="`${zamuPurple2}`" >   
            <v-layout justify-space-around justify-center>
                <v-flex xs12 sm12 offset-sm1 class="mt-10">
                         <v-card flat tile color="" width="90%" class="transparent main-content-products" :class="{'full-width pa-4': is_screen_small}">
                            <v-card-title primary-title class="justify-center px-0">
                                   <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading">Key Services</h3><br />
                                   <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading">Key  <br /> Services</h3><br />

                                    <v-card tile flat :color="`${zamuGrey1}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-white"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row align="center" justify="center">
                                <v-col cols="12"  lg="5" md="5" sm="6" xs="12" class="" v-for="(product, index) in products" :key="index">
                                    <v-card tile flat :color="`${product.color}`" min-height="568"  align="center" justify="center">
                                        <v-card-text class="section-services" align="left" justify="left">
                                            <v-row>
                                                  <v-col  lg="12" md="12" sm="12" xs="12"  cols="12" align="center" justify="center">
                                                        <v-icon size="28" :color="`${zamuBlack}`" :class="`${product.class}-icon`">{{ product.icon }}</v-icon>

                                                        <v-divider :color="`${zamuBlack}`" class="icon-divider top-divider" :class="`${product.class}-hr`"></v-divider>
                                                        <v-divider :color="`${zamuBlack}`" class="icon-divider" :class="`${product.class}-hr`"></v-divider>

                                                    </v-col>
                                                <v-col cols="12" lg="12" md="12" sm="12" xs="12" align="center" justify="center" :class="`${product.class}`">
                                                    <h3 class="zamu-sub-heading product-header mt-0">{{ product.title }}</h3>
                                                    <p class="mt-4" v-html="product.text"></p>
                                                </v-col>

                                            </v-row>
                                        </v-card-text>                                    
                                    </v-card>
                                </v-col>
                            </v-row>
                         </v-card>
                </v-flex>
            </v-layout>
        </section>
  
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: "Services",
    mixins: [colorMixin, coreMixin],
     computed: {
        products () {
            const data = [
                {
                    image: 'Pie_chart.png',
                    title: 'Mobile banking',
                    text: `Designed to meet the dynamic client base of SACCOs, the ZAMUPAY's mobile banking solution utilizes modern technology to deliver secure, efficient & convenient services by simplifying operations and delivering enhanced service to the customers. SACCO customers can use the solution for:
                    <ul class="text-left mt-3">
                        <li>Account to M-PESA transfers</li>
                        <li>Real-time M-PESA deposits to a SACCO account</li>
                        <li>Balance Inquiries &amp; Mini statements</li>
                        <li>Mobile Loans</li>
                        <li>Bank Transfers via Pesalink &amp; EFT</li>
                        <li>Airtime purchase</li>
                        <li>Utility Bill payments e.g. KPLC, TV, Water among others.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                    
                        `,
                    color: '#d29f2a',
                    icon: 'mdi-cellphone-nfc',

                },
             
                 {
                    image: 'Note.png',
                    title: 'Payments (Bank transfers & Mobile money)',
                    text: `
                    <p>SACCO clients can send and receive funds through Bank Transfers (Pesalink, RTGS, and EFT etc.) and Mobile Money Networks (M-PESA, Airtel Money etc.).</p>
                    <p>In addition, SACCOs looking to automate and increase efficiency in payment processing and management can easily integrate their system to our secure payments gateway to make and receive real-time single or bulk payments. The following payment types are supported:</p>
                    <ul class="text-left mt-3">
                    <li>Pay Bill integration</li>
                    <li>Single and bulk M-PESA disbursements</li>
                    <li>Single and bulk bank account transfers</li>
                    <li>Airtime Purchases</li>
                    <li>Utility Bill payments and integrations</li>
                    </ul>
                    `,
                    color: '#d29f2a',
                    icon: 'mdi-credit-card-outline',
                }
             
            ];
            return data;

        },

    },

}
</script>

<style>
.icon-holder {
    height: 100%;
    width: 100%;
}
.icon-holder .v-icon {
    font-size: 40px !important;
    color: #161616;
}
.full-width {
    width: 100% !important;
}

</style>